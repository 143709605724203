import { Core } from "@mescius/activereportsjs";

export function licenseActiveReports() {
  Core.setLicenseKey(
    "*.tocoman.fi,442237267996365#B1SWfJHcwdGWklUTQJ7RBdHWQF7aBF7c6clU8ETYZVmc0ZjTRZHO9ZHdHFHeGxUV79UarB5RrZWVHVjTysGcBZETxNTas56aI3ERMp5Txd5TndzUylkdRxkNHVEZhRlQaRWSoZjS7I7ZyU4NVhkMhlFcwVESnJjWPhDSIVnTvAHakh6dUFnN534MtJ4K82WRORmY6YmealGZ9clSGpVcmh4T8FzdTxmYGhEeHdzMlt4d9ZDcpR4ZkxGModjQ6JXe4VDZ6gTe8FETzpUW4Mlcj3iMy24ZNtiQwZmY7oXYUVVbId7QlBFRHFje6IESUVkI0IyUiwiIFlzMDhDOERjI0ICSiwiN5ETN5cDN8YTM0IicfJye=#Qf35VfiYFTL9kI0IyQiwiI5YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsISNyMzM6ADI8EDMxQjMwIjI0ICdyNkIsISam9ibh56bj3GduoiI0IyctRkIsISbvNWatRWQiojIh94QiwiI5YzM6kTO7YjM7MjMyQDNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQ5l6YN3EVjdVQrxmQyd7bRdnVCJkZwNnZ49EdV3EMP5WbntyKkNmex56LnR7Ny5EWThnW746T5IkNtJ7Tm5kUCZWattkYro4KMNnUHNzMjpGTUpGdrh6TmREby8GVEtmettycXZVQ3I"
  );
  Core.setLicenseKey(
    "Designer-955467264692412#B1LMxR7RmVGb8tkSad7b7k4SXVDW0pWU6I5aqllU6hEZCl5V0J5dSJGO6UVVJlHNpJ5SzkjZsF7L7VkWYR5cLdkMG5mMmRzKWpUeDdDcTJnQ7ZDWsl5NVlXTw56TZZzV8JEenp6NBVVQE96R4oHNtd6a0NjbwlWdWd7SpVkZWFmdp94QyVWN0hXUwN6MlF7bZdXVmVTOrkXTyo7Z5JGe6A7K7E5KxFTTMR6dzg7SHpVWPJkUp3mYiN7Q9VFdp5EbsVleGB7SuV7cNN7M8MjU5RzctlGR03SWOhlQ9g6bz86d8RDRsdUbHhVVKZTRpFEMhJiOiMlIsIiRxIUMDNDMyIiOigkIsUTNzETN8kDO0IicfJye#4Xfd5nIWx4SOJiOiMkIsISNWByUKRncvBXZSVmdpR7YBJiOi8kI1tlOiQmcQJCLikDNwMjNwACOxATM4IDMyIiOiQncDJCLiAjLw8CMuAzLyVmbnl6clRmI0IyctRkIsISbvNWatRWQiojIh94QiwiIyEDNykjN4YjM7YDN5UTOiojIklkIs4XXbpjInxmZiwSZ5JHd0IiczRmI1pjIs9WQisnOiQkIsISP3ElREhVMRhlVxhlelV4drJTN524KiJEWyRlbKJEOvJnMwMUOvFUTqhkWvZ5VpdEWvUncFlUUTl7YLZVT7g4QVBDMvtGbVhXaNl7S8hGRCFnWKdjdypnSxlFd754Z9MXaodFWpRPKZJ"
  );
}
